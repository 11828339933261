<template>
  <div class="partner-home">
    <div class="app-loader" v-if="businessFetchWaiting">
      <span class="app-loader__message">Loading your businesses...</span>
      <v-progress-circular
        class="mx-auto"
        :size="70"
        :width="7"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-row v-else-if="businessFetchError">
      <v-alert style="width: 100%" type="error">
        Could not fetch the business list. {{ businessFetchError }}
      </v-alert>
    </v-row>
    <div class="partner-home__container" v-else>
      <v-row>
        <v-col col="12">
          <div class="actions">
            
            <add-reseller-tokens-dialog>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on">
                  Add Tokens
                </v-btn>
              </template>
            </add-reseller-tokens-dialog>

          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col col="12">
          <v-card color="secondary" dark to="/partner/businesses">
            <div class="justify-space-between">
              <v-card-title
                class="headline text-center justify-center"
                v-text="'Total Businesses'"
              ></v-card-title>
              <v-card-text class="display-3 text-center">
                {{ businessesLinked }}
              </v-card-text>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col col="12">
          <v-card color="secondary" dark to="/partner/businesses">
            <div class="justify-space-between">
              <v-card-title
                class="headline text-center justify-center"
                v-text="'Premium Businesses'"
              ></v-card-title>
              <v-card-text class="display-3 text-center">
                {{ totalSalesMade }}
              </v-card-text>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col col="12">
          <v-card color="secondary" dark to="/partner/businesses">
            <div class="justify-space-between">
              <v-card-title
                class="headline text-center justify-center"
                v-text="'Money Earned'"
              ></v-card-title>
              <v-card-text class="display-3 text-center">
                {{ totalCommissionMade }}
              </v-card-text>
            </div>
          </v-card>
        </v-col>
      </v-row> -->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import AddResellerTokensDialog from '../../components/AddResellerTokensDialog.vue';

function copyToClipboard(text) {
  var dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

export default {
  components: { AddResellerTokensDialog },
  computed: {
    ...mapGetters({
      activeAccount: "partner/active",
      businessFetchWaiting: "partnerBusiness/businessFetchWaiting",
      businessFetchError: "partnerBusiness/businessFetchError",
      businessList: "partnerBusiness/businessList",
    }),
    businessesLinked() {
      if (this.businessList.length === 100) {
        return 100+"+";
      }
      return this.businessList.length;
    },
    totalSalesMade() {
      const businessWithSales = this.businessList.filter(
        (x) => x.activePlanId != null
      );
      return businessWithSales.length;
    },
  },
  methods: {
    ...mapActions({
      fetchBusinesses: "partnerBusiness/fetchBusinesses",
    }),
    getFormattedDate(date) {
      return date ? moment(date).format("ll") : "-";
    },
    sharePartnerLink() {
      // Get the business open link here
      const partnerLink = this.activeAccount.shareLink;
      if (partnerLink) {
        if (navigator.share) {
          navigator
            .share({
              title: "Zobaze POS",
              text: "Go digital with Zobaze POS.",
              url: partnerLink,
            })
            .then(() => console.log("Successful share"))
            .catch((error) => console.log("Error sharing", error));
        } else {
          copyToClipboard(partnerLink);
          this.$notifier.showMessage({
            content: "Link copied to clipboard",
          });
        }
      } else {
        this.$notifier.showMessage({
          content:
            "Share code has not been created for this account. Please contact us via chat or email to create it",
        });
      }
    },
  },
  created () {
    this.fetchBusinesses({
        activeAccount: this.activeAccount,
        bid: null,
        options: null,
    })
    
  },
};
</script>

<style>
.home {
  min-height: 100%;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.centered-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
</style>
