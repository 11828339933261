<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" dark :attrs="attrs" :on="on">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Open Dialog
        </v-btn>
      </slot>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Confirm action</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col col="12">
              <h3>
                Add tokens to reseller account: <b>{{ active.code }}</b>
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-text-field
                label="Tokens"
                color="primary"
                type="number"
                placeholder="numbers only"
                v-model="tokens"
                prepend-icon="mdi-mail"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-text-field
                label="Notes"
                color="primary"
                type="text"
                v-model="note"
                prepend-icon="mdi-note"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="addResellerTokens">
          Add tokens
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import partnerService from "../services/partner"


export default {
  components: {
  },
  data() {
    return {
      dialog: false,
      tokens: 0,
      note: "",
    };
  },
  computed: {
    ...mapGetters({
      activeAccount: "realm/active",
      email: "business/email",
      active: "partner/active",
    }),
  },
  methods: {
    async addResellerTokens() {
      try {
        this.$notifier.showMessage({
          content: "Updating..Please wait...",
        });
        await partnerService.addTokensToReseller({
          tokens: this.tokens,
          note: this.note,
          resellerId: this.active.id,
        });
        this.$notifier.showMessage({
          content: "Successfully updated tokens",
        });
        this.dialog = false;
        this.$emit("update");
      } catch (error) {
        this.$notifier.showMessage({
          content:
            "Add tokens failed. Error: " + error.message || "unknown",
        });
      }
    },
  },
  mounted() {
    if (this.email) this.emailAddress = this.email;
  },
};
</script>
